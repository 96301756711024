
import {
    computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref,
} from 'vue';

export default defineComponent({
    name: 'inventory-screen',
    components: {},
    props: {},
    setup(props, context) {
        return {};
    },
});
